$total-red: #ff0000;
$total-blue: #285aff;
$total-light-blue: #009bff;
$total-turquoise: #32c8c8;
$total-green: #28c896;
$total-light-green: #96e600;
$total-orange: #ffc800;
$total-grey: #374649;

$total-red-gradient: linear-gradient(90deg, #ff6e00 0%, #ff0000 50% );
$total-blue-gradient: linear-gradient(90deg, #4632ff 0%, #00aaff 50% );
$total-light-blue-gradient: linear-gradient(90deg, #0082ff 0%, #00c8ff 50% );
$total-turquoise-gradient: linear-gradient(90deg, #00aaff 0%, #00c8aa 50% );
$total-green-gradient: linear-gradient(90deg, #00c8af 0%, #78d700 50% );
$total-light-green-gradient: linear-gradient(90deg, #3cd700 0%, #ffd700 50% );
$total-orange-gradient: linear-gradient(90deg, #ffdc00 0%, #ffaf00 50% );

$total-rainbow: linear-gradient(90deg, $total-red 0%, $total-blue 16%, $total-light-blue 32%, $total-turquoise 48%, $total-green 64%, $total-light-green 80%, $total-orange 96% );
$total-gradient-green-blue: linear-gradient(90deg, $total-blue 0%, $total-green 50%);
$total-gradient-red-orange: linear-gradient(90deg, $total-red 0%, $total-orange 50%);

$total-font-family-1: "Roboto", sans-serif;
$total-font-family-2: "Arial", sans-serif;
