/* You can add global styles to this file, and also import other style files */
@import "src/app/utils/theme-variable";

:root {
  --total-red: #ff0000;
  --total-blue: #285aff;
  --total-light-blue: #009bff;
  --total-turquoise: #32c8c8;
  --total-green: #28c896;
  --total-light-green: #96e600;
  --total-orange: #ffc800;
  --total-grey: #374649;
  --total-outline-btn-red-hover: #fcd7d7;
  --total-outline-btn-orange-hover: #ffecbf;
  --total-outline-btn-green-hover: #ceffbf;

  --total-red-gradient: #ff0000, #ff6e00;
  --total-blue-gradient: #4632ff, #00aaff;
  --total-light-blue-gradient: #0082ff, #00c8ff;
  --total-turquoise-gradient: #00aaff, #00c8aa;
  --total-green-gradient: #00c8af, #78d700;
  --total-light-green-gradient: #3cd700, #ffd700;
  --total-orange-gradient: #ffdc00, #ffaf00;

  --total-rainbow: var(--total-red), var(--total-blue), var(--total-light-blue), var(--total-turquoise), var(--total-green), var(--total-light-green), var(--total-orange);
  --total-gradient-green-blue: var(--total-blue), var(--total-green);
  --total-gradient-red-orange: var(--total-red), var(--total-orange);
  --total-gradient-orange-red: var(--total-orange), var(--total-red);

  --b2b-main-color: var(--total-light-blue);
  --b2b-main-color-gradient: var(--total-light-blue-gradient);
  --b2b-box-color-gradient: var(--total-light-blue-gradient);
  --b2b-title-color-gradient: var(--total-light-blue-gradient);
  --b2b-timeline-icon-color: var(--total-green);
  --b2b-timeline-title-color-gradient: var(--total-green-gradient);

  --b2c-main-color: var(--total-green);
  --b2c-main-color-gradient: var(--total-green-gradient);
  --b2c-box-color-gradient: var(--total-green-gradient);
  --b2c-title-color-gradient: var(--total-green-gradient);
  --b2c-timeline-icon-color: var(--total-light-blue);
  --b2c-timeline-title-color-gradient: var(--total-light-blue-gradient);

  --theme-color: var(--total-green);
  --theme-color-gradient: var(--total-red-gradient);
  --theme-color-red-gradient: var(--total-red-gradient);
  --theme-color-box: var(--total-red-gradient);
  --theme-color-title: var(--total-red-gradient);
  --theme-icon-timeline: var(--total-light-blue);
  --theme-icon-timeline-title: var(--total-light-blue-gradient);
  --theme-button-color-primary: var(--total-light-blue);
  --theme-button-color-success: var(--total-green);
  --theme-button-color-warning: var(--total-red);

}

.h-100 {
  height: 100%;
}

.left-menu {
  -moz-box-shadow: inset 0 0 3px lightgray;
  -webkit-box-shadow: inset 0 0 3px lightgray;
  box-shadow: inset 0 0 3px lightgray;
}

@font-face {
  font-family: 'Netto Offc W02 Bold';
  src: url('./assets/fonts/1379068/9708408d-8f84-4042-88a1-6d6c86a4fd79.woff2') format('woff2'),
  url('./assets/fonts/1379068/845ccfeb-47f7-4947-9323-c2df73ac7649.woff') format('woff');
}

.clr-vertical-nav .nav-link.active .nav-icon {
  fill: var(--theme-color);
}

.clr-vertical-nav .nav-link.active {
  border-right: 4px solid var(--theme-color);
}

button {
  outline: none !important;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  margin: 0;
  font-family: 'Roboto', sans-serif;
}

.clr-input {
  width: 220px;
}

.clr-select-wrapper select,
.clr-multiselect-wrapper select {
  width: 220px;
}

.clr-page-size-select {
  width: 60px !important;
}

.badge-success{
  background: #67BC2D !important;
  padding: 2px !important;
}
.badge-ignore{
  background: lightgrey !important;
  padding: 2px !important;
}
.badge-error{
  background: #E15A39 !important;
  padding: 2px !important;
}

.sectionTitle:before {
  content: '\A';
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 5px;
  display: inline-block;
  background: var(--theme-color);
}

.container {
  max-width: 1400px;
  margin: 25px auto;
}

.block-center {
  margin: auto;
}

.text-center {
  text-align: center;
}

.snackbar-success {
  background-color: #1b6c34 !important;
  color: white !important;
}

.snackbar-error {
  background-color: #c90000 !important;
  color: white !important;
}

.clr-input[readonly] {
  min-width: 175px;
}

/**
 BUTTON STYLE
 */

.btn-te {
  background: #ed0000 !important;
}

.btn-te-border {
  background-color: #fff;
  border-radius: 6px;
  border-color: #029beb !important;
  color: #029beb !important;
}

/*
. {
  border-color: var(--theme-color) !important;
  background-color: var(--theme-color) !important;
  color: #fff;
}

. clr-icon {
  color: #fff;
}

.:hover {
  border-color: #1b6c34 !important;
  background-color: #268d1b !important;
  color: #fff;
}

.btn-outline {
  border-color: var(--theme-color) !important;
  color: var(--theme-color) !important;
}
.btn-outline clr-icon {
  color: var(--theme-color);
}

.btn-outline:hover {
  border-color: var(--theme-color) !important;
  color: var(--theme-color) !important;
  background-color: #f4fef3;
}
 */

.btn.btn-success {
  border-color: var(--total-green) !important;
  background-color: var(--total-green) !important;
}

.btn.btn-success:hover {
  border-color: var(--total-light-green) !important;
  background-color: var(--total-green) !important;
}

.btn.btn-primary {
  border-color: var(--total-light-blue) !important;
  background-color: var(--total-light-blue) !important;
}

.btn.btn-primary:hover {
  border-color: var(--total-turquoise) !important;
  background-color: var(--total-light-blue) !important;
}

.btn.btn-warning {
  border-color: var(--total-red) !important;
  background-color: var(--total-red) !important;
}

.btn.btn-warning:hover {
  border-color: var(--total-orange) !important;
  background-color: var(--total-red) !important;
}

.btn.btn-warning-outline {
  color: var(--total-red) !important;
  border-color: var(--total-red) !important;
}

.btn.btn-warning-outline:hover {
  background-color: var(--total-outline-btn-red-hover);
  color: var(--total-red) !important;
  border-color: var(--total-red) !important;
}

.btn.btn-success-outline {
  color: var(--total-green) !important;
  border-color: var(--total-green) !important;
  //background-color: var(--total-green) !important;
}

.btn.btn-success-outline:hover {
  border-color: var(--total-green) !important;
  background-color: var(--total-outline-btn-green-hover) !important;
}

.btn-outline-success-icon {
  color: var(--total-green);
}

.cancel-button-icon {
  color: var(--total-red) !important;
}

nav.breadcrumb {
  margin: 10px 0;
}

nav.breadcrumb > a {
  font-size: 18px;
  color: var(--theme-color);
}

nav.breadcrumb > a:hover {
  text-decoration: none;
}

nav.breadcrumb > a:before {
  content: '/';
  color: var(--theme-color);
  vertical-align: top;
  display: inline-block;
  font-weight: normal;
  font-style: normal;
  font-size: 25px;
  margin: 0 10px 0 8px;
  -webkit-font-smoothing: antialiased;
}

nav.breadcrumb > a:first-child:before {
  display: none;
}

nav.breadcrumb > a:last-child {
  color: var(--theme-color);
}

.load-full-screen {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
  background-color: rgba(93, 101, 100, 0.7);
  z-index: 1500;
  transition: 0.2s ease opacity;

  &.active {
    opacity: 1;
    visibility: visible;

    &:hover {
      cursor: wait;
    }
  }
}

.loader-parent {
  margin: 0;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}


/**
 Phone
 */
.prefix {
  width: 35px !important;
  margin-right: 2px;
}

.phone-after-prefix {
  width: 121px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* collapse */

.collapsible-section {
  margin-bottom: 0.2rem;
  margin-top: 0;
}

input[type='checkbox'] {
  display: none;
}

.collapsible-toggle-filter {
  display: block;
  font-weight: normal;
  font-family: 'Roboto', sans-serif;
  font-size: 0.6rem;
  text-align: right;
  padding: 0.2rem;
  color: dimgrey;
  background: transparent;
  cursor: pointer;
  border-radius: 3px;
  transition: all 0.25s ease-out;
  width: 100% !important;
  text-transform: uppercase;
}

.collapsible-toggle-filter:hover {
  color: #5EAAF7;
}

label .collapsible-toggle-filter .clr-col-12 .clr-col-md-2 {
  max-width: 100%;
}

.collapsible-toggle-filter::before {
  content: ' ';
  display: inline-block;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid #5EAAF7;
  vertical-align: middle;
  margin-right: .7rem;
  transform: translateY(-2px);
  transition: transform .2s ease-out;
}

.collapsible-toggle-input:checked + .collapsible-toggle-filter::before {
  transform: rotate(90deg) translateX(-3px);
}

.collapsible-section-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height .25s ease-in-out;
}

.collapsible-toggle-input:checked + .collapsible-toggle-filter + .collapsible-section-content {
  max-height: 750px;
}

.collapsible-toggle-input:checked + .collapsible-toggle-filter {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.search-box {
  background: white !important;
}

.filters-selection .clr-combobox-wrapper.multi {
  background-color: white;
  border: none;
  border-bottom: solid gray 1px;
}


/// -------- TE ---------

.mainContainerTitle {
  width: fit-content;
  margin-top: 2px;
  font-size: 1.1rem;
  background: linear-gradient(90deg, var(--theme-color-gradient));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 2.1rem !important;
  font-family: 'Roboto', sans-serif !important;
}

.secondTotalTitle {
  width: fit-content;
  margin-top: 5px !important;
  font-size: 0.7rem;
  background: linear-gradient(90deg, var(--theme-color-red-gradient));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 1.7rem !important;
  font-family: 'Roboto', sans-serif !important;
}

.sectionTitle {
  font-size: 0.8rem !important;
  margin-bottom: 8px;
  background: var(--theme-color);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 0.8rem !important;
  font-weight: lighter !important;
}

.header-default {
  background-color: white; // Logo total always on white
  box-shadow: none;
  border: 0;
  border-bottom: 3px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(90deg, var(--total-rainbow));
  height: 60px;
}

.branding {
  height: 4rem;
}

.title {
  line-height: 4rem;
  font-size: 0.9rem;
  color: #c90000;
}

.clr-toggle-wrapper input[type='checkbox']:checked + label::before {
  border-color: var(--theme-color) !important;
  background-color: var(--theme-color) !important;
  transition-property: border-color, background-color;
}

.clr-radio-wrapper input[type=radio]:checked + label::before {
  box-shadow: inset 0 0 0 0.3rem var(--theme-color) !important;
  border: 0;
}

.app-nav {
  box-shadow: none;
  border: 0;
  border-right: 3px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(0deg, rgba(0, 155, 255, 1) 0%, rgba(50, 200, 200, 1) 50%);
}

.card {
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border: 0px solid transparent;
  padding: 1px;
  /*
  background-image: linear-gradient(white, white),
  linear-gradient(90deg, var(--theme-color-box));
   */
  border-radius: 10px;
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.card-block {
  border-radius: 10px;
  border: 0;
}

.filters-card {
  padding: 0px !important;
  background-image: none !important;
}

.section-title {
  width: fit-content;
  background: var(--theme-color-gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gradient-text-green-blue {
  width: fit-content;
  background: $total-gradient-green-blue;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gradient-text-red-orange {
  width: fit-content;
  background: $total-gradient-red-orange;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gradient-text-red {
  width: fit-content;
  background: $total-red-gradient;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gradient-text-blue {
  background: $total-blue-gradient;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gradient-text-light-blue {
  background: $total-light-blue-gradient;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gradient-text-green {
  width: fit-content;
  background: $total-green-gradient;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gradient-text-light-green {
  width: fit-content;
  background: $total-light-green-gradient;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gradient-text-orange {
  width: fit-content;
  background: $total-orange-gradient;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gradient-text-turquoise {
  width: fit-content;
  background: $total-turquoise-gradient;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.clr-control-label {
  font-family: $total-font-family-1;
}

.btn {
  border-radius: 5px;
}

.loader {
  border: 10px solid #f3f3f3;
  border-radius: 50%;
  border-top: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid $total-red;
  border-left: 10px solid $total-red;
  width: 60px;
  height: 60px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.img-icon {
  height: 30px;
}

.alert-app-level .alert-warning {
  color: var(--total-red);
  background: var(--total-orange) !important;
  margin-top: 5px;
  box-sizing: content-box;
  border-width: 1px;
  border-style: solid;
  border-image: linear-gradient(90deg, var(--total-gradient-red-orange));
  border-image-slice: 1;
}

.alert-app-level .alert-icon {
  color: var(--total-red);
}

// ------- for collapse

.clr-accordion-header {
  background: white !important;
  border-color: transparent;
  border-radius: 30px !important;
  border-bottom: none;
}

.clr-accordion-panel-open .clr-accordion-content {
  border-color: transparent;
}

.clr-accordion-panel {
  border: none;
  border-radius: 0px !important;
}

.clr-accordion-panel:last-child .clr-accordion-content {
  border: none;
}

.clr-accordion:not(.clr-stepper-forms) .clr-accordion-panel:last-child .clr-accordion-content, .clr-accordion:not(.clr-stepper-forms) .clr-accordion-panel:last-child .clr-accordion-header {
  border: none;
}

.clr-accordion:not(.clr-stepper-forms) .clr-accordion-inner-content {
  border: none;
  box-shadow: inset 0px 11px 8px -10px #CCC;
  //border-top: 1px solid var(--theme-color);
}

.clr-accordion:not(.clr-stepper-forms) .clr-accordion-panel:last-child .clr-accordion-content,
.clr-accordion:not(.clr-stepper-forms) .clr-accordion-header {
  border-bottom-left-radius: 30px !important;
  border-bottom-right-radius: 30px !important;
}

.collapsible-card-blok {
  padding: 0px !important;
  margin-bottom: -10px;
}

.accesssPointSection {
  padding: 5px 0% 15px;
  margin-top: 5px;
  width: 90%;
  min-width: 840px;
  margin-right: auto;
  margin-left: auto;
}

.access-point-card, .site-main-infos-card {
  width: auto;
  margin-top: 15px;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.site-main-infos-card {
  margin-top: 20px;
}

.site-main-infos-section {
  padding: 5px 0% 15px;
  margin-top: 5px;
  width: 90%;
  margin-right: auto;
  margin-left: auto;
}

.delivery-points-title {
  width: fit-content;
  font-size: 20px !important;
  background: linear-gradient(90deg, var(--theme-color-gradient));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: 'Roboto', sans-serif !important;
  margin-bottom: 15px;
}

.delivery-points-title-icon {
  color: var(--theme-color);
}


// filters
.main-filters-container {
  border: solid 1px lightgrey;
  margin-top: 15px
}

.filters-container {
  display: flex;
  background-color: #fff;
  border: 0px solid transparent;
  padding-bottom: 2px;
  padding-left: 0px;
  padding-right: 0px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-origin: border-box;
  background-clip: content-box, border-box;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.filters-container-content {
  display: flex;
  margin-bottom: 10px;
}

.reg-val-accordion-title {
  width: fit-content;
  background: linear-gradient(90deg, var(--theme-color-gradient));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: 'Roboto', sans-serif !important;
}

.reg-val-accordion-title-icon {
  color: var(--theme-color);
}


/**
BULLET
 */

.stage {
  width: 30px;
  height: 30px;
  perspective: 40px;
  perspective-origin: 50% 50%;
}

.ball .shadow {
  position: absolute;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle at 50% 50%, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.1) 40%, rgba(0, 0, 0, 0) 50%);
  transform: rotateX(90deg) translateZ(-10px);
  z-index: -1;
}

.ball {
  display: inline-block;
  width: 100%;
  height: 100%;
  margin: 0;
  border-radius: 50%;
  position: relative;
  background: radial-gradient(circle at 50% 120%, #488F35, #7DD566 80%, #38BB15 100%);
}

.ball-error {
  background: radial-gradient(circle at 50% 120%, red, indianred 80%, darkred 100%);
}

.ball-warn {
  background: radial-gradient(circle at 50% 120%, orange, darkorange 80%, orangered 100%);
}

.ball:before {
  content: "";
  position: absolute;
  background: radial-gradient(circle at 50% 120%, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0) 70%);
  border-radius: 50%;
  bottom: 2.5%;
  left: 5%;
  opacity: 0.6;
  height: 100%;
  width: 90%;
  filter: blur(5px);
  z-index: 2;
}

.ball:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 5%;
  left: 10%;
  border-radius: 50%;
  background: radial-gradient(circle at 50% 50%, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8) 14%, rgba(255, 255, 255, 0) 24%);
  transform: translateX(-10px) translateY(-10px) skewX(-20deg);
  filter: blur(2px);
}
